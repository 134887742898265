// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
const images = require.context('../images', true)
import "channels"
import "../css/application.scss"
import "@iconscout/unicons/css/line"
import "react-datepicker/dist/react-datepicker.css";


